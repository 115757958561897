import {
  type AttributeDefaultValue,
  type AttributeEventValue,
  isDefaultValueAttribute,
  isRefAttribute,
  isUb64Attribute,
} from "./nameParser.js";
import {
  jsonParseOrUse,
  loggerScope,
  returnWithCache,
  mergeParameterValues,
  ParameterMergeOptions,
} from "./utils.js";

import { AttributeNameBase, isRelevantAttribute, parseAttributeName } from "./nameParser.js";

export const UNNAMED_ATTRIBUTE = "unnamed";

const log = loggerScope.scope("parser");

/**
 *
 */
const attributeRefCache = new WeakMap<HTMLElement, Map<string, HTMLScriptElement>>();

/**
 *
 *
 *
 *
 *
 */
export function getRefElement(
  element: HTMLElement,
  selector: string,
): { textContent?: string | null } {
  if (!attributeRefCache.has(element)) {
    log.trace("Creating new ref cache for element", element);
    attributeRefCache.set(element, new Map());
  }
  /*                   */
  const elementCache = attributeRefCache.get(element)!;

  /*             */
  if (elementCache.has(selector)) {
    log.trace("Cache hit for ref element", selector);
    return elementCache.get(selector)!;
  }

  const refElement = element.querySelector<HTMLScriptElement>(selector);
  if (!refElement) {
    log.warn("Reference element not found", selector);
    return {};
  }

  /*                                      */
  return returnWithCache(elementCache, selector, refElement);
}

/**
 *
 *
 *
 *
 */
const attributeValueCache = new Map<string, unknown>();

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function getAttributeValue(
  element: HTMLElement,
  attr: AttributeDefaultValue | AttributeEventValue,
): unknown | undefined {
  /*                                     */
  let value: string | null | undefined = element.getAttribute(attr[0])!;

  if (isRefAttribute(attr)) {
    /*                                  */
    value = getRefElement(element, value).textContent;
  }

  if (!value) {
    /*                             */
    return undefined;
  }

  if (isUb64Attribute(attr)) {
    value = atob(value);
  }

  if (attributeValueCache.has(value)) {
    log.trace("Cache hit for attribute value", value);
    return attributeValueCache.get(value);
  }

  /*                      */
  return returnWithCache(attributeValueCache, value, jsonParseOrUse(value));
}

type GetParametersAccumulator = Record<
  string,
  [
    attr: AttributeDefaultValue | AttributeEventValue,
    defaultValue?: ReturnType<typeof getAttributeValue>,
    identifierValue?: ReturnType<typeof getAttributeValue>,
  ]
>;

/**
 *
 *
 *
 *
 *
 */
export function toParamAccumulator(
  this: HTMLElement,
  acc: GetParametersAccumulator,
  parsed: AttributeEventValue | AttributeDefaultValue,
): GetParametersAccumulator {
  const paramName = parsed[1];

  /*                              */
  if (!acc[paramName]) {
    acc[paramName] = [parsed];
  }

  /*                       */
  const paramData = acc[paramName];
  /*                                               */
  const value = getAttributeValue(this, parsed);

  /*                  */
  if (isDefaultValueAttribute(parsed)) {
    paramData[1] = value;
    return acc;
  }

  /*                              */
  paramData[2] = value;
  return acc;
}

/**
 *
 *
 *
 */
export function guardUnnamed(
  unnamed: GetParametersAccumulator[string] | undefined,
): [defaultValue: Record<string, unknown>, identifierValue: Record<string, unknown>] {
  return [{ ...(unnamed?.[1] as object) }, { ...(unnamed?.[2] as object) }];
}

/**
 *
 *
 *
 *
 *
 *
 */
export function parseParameters<T extends Record<string, unknown>>(
  base: AttributeNameBase,
  identifier: string,
  element: HTMLElement,
  options: {
    /**
 *
 *
 *
 */
    expandUnnamedAs?: string;
    /**
 *
 *
 */
    extraParameters?: T;

    /**
 *
 */
    excludeCamelcasify?: string[];
  } & ParameterMergeOptions = {},
): T {
  const {
    extraParameters = {} as T,
    expandUnnamedAs = UNNAMED_ATTRIBUTE,
    excludeCamelcasify = [],
    ...mergeOptions
  } = options;

  /*                                             */
  const { [UNNAMED_ATTRIBUTE]: unnamed, ...attributeValueMap } = element
    .getAttributeNames()
    .map((a) => parseAttributeName(base, a, expandUnnamedAs, excludeCamelcasify))
    .filter((a) => isRelevantAttribute(a, identifier))
    .reduce(toParamAccumulator.bind(element), {});

  /*                                 */
  const unnamedParam = guardUnnamed(unnamed);

  /*                                                        */
  const keys = new Set<string>(
    [unnamedParam[0], attributeValueMap, unnamedParam[1], extraParameters].flatMap((m) =>
      Object.keys(m),
    ),
  );

  /*                                                                             */
  /*                                                                                           */
  /*    */
  const result = {} as Record<string, unknown>;
  keys.forEach((key) => {
    result[key] = mergeParameterValues(
      key,
      [
        /*             */
        unnamedParam[0][key],
        attributeValueMap[key]?.[1],
        /*                  */
        extraParameters[key],
        /*                         */
        unnamedParam[1][key],
        attributeValueMap[key]?.[2],
      ],
      mergeOptions,
    );
  });

  log.debug("Extracted parameters from element", result);
  return result as T;
}
