/*                                                         */
import { camelcasify } from "@otto-ec/assets-core-utils/string";
import { loggerScope, returnWithCache } from "./utils.js";

const log = loggerScope.scope("name-parser");

/**
 *
 *
 *
 *
 */
export const MODIFIERS = ["ub64e", "ref"] as const;

/**
 *
 *
 *
 *
 *
 */
export const DELIMITERS = ["-", ".", "."] as const;

/**
 *
 */
export const GROUPS = ["identifier", "paramOrModifier", "modifier"] as const;

/**
 *
 */
export const attributeNamePattern = new RegExp(
  `^(?:${DELIMITERS[0]}(?<${GROUPS[0]}>[\\w-]+))?(?:\\${DELIMITERS[1]}(?<${GROUPS[1]}>[\\w-]+)(?:\\${DELIMITERS[2]}(?<${GROUPS[2]}>\\w+)?)?)?$`,
);

/**
 *
 *
 *
 *
 */
export type Modifier = (typeof MODIFIERS)[number];

/**
 *
 *
 *
 */
export const attributeNameCache = new Map<string, ReturnType<typeof parseAttributeName>>();

/**
 *
 *
 *
 *
 *
 */
export type AttributeMetaData = [
  attribute: string,
  paramName: string,
  modifier: Modifier | undefined,
];

/**
 *
 */
export type AttributeEventValue = [...AttributeMetaData, eventName: string];

/**
 *
 */
export type AttributeDefaultValue = [...AttributeMetaData, isDefault: true];

/**
 *
 *
 *
 *
 */
export function isUb64Attribute(attr: [...AttributeMetaData, unknown]): boolean {
  return attr[2] === MODIFIERS[0];
}

/**
 *
 *
 *
 *
 */
export function isRefAttribute(attr: [...AttributeMetaData, unknown]): boolean {
  return attr[2] === MODIFIERS[1];
}

/**
 *
 *
 */
export function isDefaultValueAttribute(
  attr: AttributeDefaultValue | AttributeEventValue,
): attr is AttributeDefaultValue {
  return attr[3] === true;
}

/**
 *
 *
 *
 *
 *
 */
export function isRelevantAttribute(
  attr: AttributeDefaultValue | AttributeEventValue | undefined,
  identifier: string,
): attr is AttributeDefaultValue | AttributeEventValue {
  return !!attr && (isDefaultValueAttribute(attr) || attr[3] === identifier);
}

/**
 *
 */
export type AttributeNameBase = `data-${string}-v${number}`;

/**
 *
 *
 *
 *
 *
 */
export function camelcasifyParam(param: string, excludeCamelcasify: string[]): string {
  if (excludeCamelcasify.includes(param)) {
    return param;
  }

  return camelcasify(param);
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function parseAttributeName(
  base: AttributeNameBase,
  attr: string,
  unnamedParameterName: string,
  excludeCamelcasify: string[],
): AttributeDefaultValue | AttributeEventValue | undefined {
  if (!attr.startsWith(base)) {
    log.trace("Skip not supported attribute", attr);
    return undefined;
  }

  /**
 *
 *
 */
  const cacheKey = `${attr}.${unnamedParameterName}`;
  if (attributeNameCache.has(cacheKey)) {
    log.trace("cache hit", cacheKey);
    return attributeNameCache.get(cacheKey);
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 */
  const attributeName = attr.replace(base, "");

  let {
    /*                                                    */
    [GROUPS[0]]: identifier,
    [GROUPS[1]]: paramOrModifier,
    [GROUPS[2]]: modifier,
  } = attributeNamePattern.exec(attributeName)?.groups as Record<
    (typeof GROUPS)[number],
    string | undefined
  >;

  /*                                                      */
  if (MODIFIERS.includes(paramOrModifier as Modifier)) {
    modifier = paramOrModifier;
    paramOrModifier = undefined;
  }

  /*                                                    */
  const param = paramOrModifier
    ? camelcasifyParam(paramOrModifier, excludeCamelcasify)
    : unnamedParameterName;

  const result: AttributeDefaultValue | AttributeEventValue = [
    attr,
    param,
    modifier as Modifier,
    identifier ?? true,
  ];

  log.debug("Parsed Attribute", attr, result);
  return returnWithCache(attributeNameCache, cacheKey, result);
}
